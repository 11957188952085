// is used to determine which property to check in each entity:
export const conf = {
    BLOCK: {
        original_id: 'block_id',
        id: 'block_fe_id',
        multi: 'is_block_multi',
        max: 'block_repeat_max',
    },
    FIELD: {
        original_id: 'field_id',
        id: 'field_fe_id',
        multi: 'multi',
        max: 'field_repeat_max',
    }
}

// check a number of copies:
export const getEntityCount = (entity, list) => {
    const entityFields = conf[entity.form_entity_type];
    const count = list.filter(el => el[entityFields.original_id] === entity[entityFields.original_id]).length;
    return count;
}

// check if field or block can be copied:
export const checkIfMulti = (entity, list) => {
    const entityFields = conf[entity.form_entity_type];
    // const count = list.filter(el => el.is_copy && (el.original_entity === entity.original_entity || el.original_entity === entity[entityFields.id])).length + 1;
    const count = list.filter(el => el[entityFields.original_id] === entity[entityFields.original_id]).length;
    return entity[entityFields.multi] &&
        (count < entity[entityFields.max] || !entity[entityFields.max]);
}

export function getFieldById(list, id, key = 'field_id') {
  for (const item of list) {
    if (item[key] === id) return item;
    if (item.entities?.length) {
      const innerResult = getFieldById(item.entities, id);
      if (innerResult) return innerResult;
    }
  }
}
export function checkIfLastOrFirst(list, id, fe_id) {
  let block_with_same_id  = list.filter(blk => blk.block_id === id)
  let last_index = block_with_same_id.length - 1
  let is_first = block_with_same_id[0]['block_fe_id'] === fe_id
  let is_last = block_with_same_id[last_index]['block_fe_id'] === fe_id
  // return  block_with_same_id[isLast  ? last_index : 0]['block_fe_id'] === fe_id

  return {is_first, is_last}
}

export const isEquel = (el, keys) => {
  let result = true;
  Object.entries(keys).forEach(([key, value]) => {
    if (typeof value === 'function') {
      if (!value(el[key])) result = false;
    } else if (el[key] !== value) result = false;
  });
  return result;
}

export function getEntityByKeyList(list = [], keys) {
  if (!list?.length) return;

  for (const item of list) {
    if (isEquel(item, keys)) return item;

    if (item.entities?.length) {
      const innerResult = getEntityByKeyList(item.entities, keys);
      if (innerResult) return innerResult;
    }

    const cascadedFields = getCascadingFields(item)
    if (cascadedFields) {
      const innerResult = getEntityByKeyList(cascadedFields, keys);
      if (innerResult) return innerResult;
    }
  }
}

function generateValidationErrors(list) {
  // console.log('generateValidationErrors', list);
  for (const [key, item] of list.$model.entries()) {
    if (list[key].$invalid) {
      let result = { item, validation: list[key] }
      if ('value' in list[key]) {
        const errors = Object.entries(list[key].value).reduce((result, [keyj, valuej]) => {
          if (keyj.indexOf('$') !== 0) result[keyj] = valuej;
          return result
        }, {})
        result = {value: item.value, errors, ...result};
      }
      if (item.section_name) {
        console.warn(`Invalid SECTION ` + `%c${item.section_name}:`, 'font-weight: bold', result);
      } else if (item.form_entity_type === 'BLOCK') {
        console.log(`Invalid ${item.form_entity_type} ` + `%c${item.block_name}:`, 'font-weight: bold', result);
      } else if (item.form_entity_type === 'FIELD') {
        console.log(`Invalid ${item.form_entity_type} ` + `%c${item.label}:`, 'color: red', result);
      } else if (item.form_entity_type) {
        console.log(`Invalid ${item.form_entity_type}:`, result);
      }
    }
    if (list[key].$model.entities?.length) {
      generateValidationErrors(list[key].entities);
    }
  }
}

export function logValidationErrors(list) {
  console.log('%c ===== VALIDATION REPORT START =====', 'font-weight: bold');
  generateValidationErrors(list);
  console.log('%c ===== VALIDATION REPORT END =====', 'font-weight: bold');
}

export function isJson(jsonString) {
  try {
      var o = JSON.parse(jsonString);
      // Handle non-exception-throwing cases:
      // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
      // but... JSON.parse(null) returns null, and typeof null === "object", 
      // so we must check for that, too. Thankfully, null is falsey, so this suffices:
      if (o && typeof o === "object") {
          return o;
      }
  }
  catch (e) { return false }

  return false;
}

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}
export const formatDate = (date) => {
  if(!date) {
    return null
  }
  date = new Date(date)
  return (
    [
      date?.getFullYear(),
      padTo2Digits(date?.getMonth() + 1),
      padTo2Digits(date?.getDate()),
    ].join('-') +
    ' ' +
    [
      '00',
      '00',
      '00',
    ].join(':')
  );
}

export const subtractMonths = (date, months) => {
  let newDt = new Date(date)
  newDt.setMonth(newDt.getMonth() - months);
  return newDt;
}

export const isObjectEmpty = (objectData) => {
  return Object.keys(objectData).length === 0
}

export const removeLocalStorageItemsWithPrefix = (prefix) => {
  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i);
    if (key && key.startsWith(prefix)) {
      localStorage.removeItem(key);
    }
  }
}

export function getCascadingFields(data) {
  // works properly for single selectors and radio:
  if (!data.value || !data.options?.length) return null
  const selectedOption = data.options.find((el) => el.option_value === data.value)
  if (!selectedOption?.cascading_fields?.length) return null
  return selectedOption?.cascading_fields
}

// ! config is used for the Form Builder requests. Change its values carefully
// TODO import app names from the separate config on the global level and link following variants to proper app names. Use this list only for the FormBuilder and the general config - for all other places
export const appList = {
  CANDIDATE: 'candidate',
  TENANT: 'analyst',
  REFEREE: 'referee',
  CLIENT: 'client',
  ADMIN: 'admin',
  CREATE: 'CreateCase' // ! "CREATE" isn't an app, get rid of its usage
}
// get section status by role depending on app:
export const sectionStatusConfig = {
  [appList.CANDIDATE]: 'status',
  [appList.TENANT]: 'status',
  [appList.REFEREE]: 'referee_status',
  [appList.CLIENT]: 'status',
  [appList.CREATE]: 'analyst_status', // ! "CREATE" isn't an app, get rid of its usage
  default: 'status',
  getStatusProperty(appName = 'default') {
    return this[appName] || this.default;
  },
}

export function checkDirtyForm (s1, s2) {
  let isDirty = false
  if (!s1.length || !s2.length) {
      return false
  }

  if (s1.entities) {
      return checkDirtyForm(s1.entities, s2.entities)
  } else {
      for (let i=0; i < s1.length; i++) {
          const s1Entity = s1[i];
          const s2Entity = s2.find(x => {
              return x.block_id ? x.block_id === s1Entity.block_id : x.field_id === s1Entity.field_id
          })

          if (s1Entity.entities) {
              return checkDirtyForm(s1Entity.entities, s2Entity.entities)
          } else {
              
              if (s1Entity.value != s2Entity.value) {
                  isDirty  = true 
                  console.log(s1Entity.value != s2Entity.value, s1Entity.value, s2Entity.value)
                  break;
              }
          }
      }

      return isDirty
  }
}

// check if cascaded fields are set for the current field's value:
export const showCascaded = (fieldData, fieldValue = fieldData.value) => {
  return !!fieldData?.options?.some((opt) => opt.option_value === fieldValue && !!opt.cascading_fields?.length);
}

export const getNewFieldPathData = (field, prevPath) => {
  const newPath = [...prevPath];
  newPath.push(field.field_fe_id);
  const pathParent = [...newPath];
  pathParent.splice(-1, 1);
  return {
    path: newPath,
    pathString: newPath.join("/"),
    pathParent: pathParent,
    pathParentString: pathParent.join("/"),
  }
}

/**
 * @typedef {object} ResetConfig
 * @property {boolean} reset determins if field values must be reset
 * @property {object} data properties to reset
 */
/**
 * @param {object} opt  option data
 * @param {ResetConfig} config
 */
function resetCascadedValues(
  opt,
  config = {},
) {
  const {
    reset = true,
    data: resetData = {},
    path = [],
  } = config;

  // in case the field data is provided reset its path:
  let newPath = path;
  if (opt.field_fe_id) {
    // reset field's path idetificator:
    const newPathData = getNewFieldPathData(opt, path);
    Object.assign(opt, newPathData);
    newPath = newPathData.path;
  }

  if (opt?.options?.length) {
    opt.options.forEach(el => {
      {
        if (reset) el.value = "";

        // reset nested cascaded/options if exist:
        resetCascadedValues(el, { ...config, path: newPath });
      }
    })
  }
  else if (opt?.cascading_fields?.length) {
    opt.cascading_fields.forEach(el => {
      // handle reset data:
      Object.assign(el, resetData);

      if (reset) el.value = "";
      // reset nested cascaded/options if exist:
      resetCascadedValues(el, config);
    })
  }
}

/**
 * @param {object} field field data
 * @param {ResetConfig} config
 */
export const handleCascadingFieldValues = (
  field,
  config = {
    reset: true,
    data: {},
  },
) => {
  if (!field) return;
  // will be called in case we pass the field data with the option list:
  field.options?.forEach(opt => {
    if (field.value != opt.option_value){
      resetCascadedValues(opt, config)
    }
  })
  // will be called in case we pass the option data with cascaded field list:
  field.cascading_fields?.forEach(el => {
    el.options?.forEach(opt => {
      if(el.value !== opt.option_value){
        resetCascadedValues(opt, config)
      }
    })
  })
}